import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Declaration: React.FC = () => {
  const declaration = '"We the people of the reality in which we live, demand the right of data sovereignty, data certainty, and data freedom."';
  return <p className="italic">{declaration} - <a style={{color: "white"}} href="https://arietrouw.com" target='_blank' rel='noopener noreferrer'>Arie Trouw</a></p>;
}

const WebbleTool: React.FC = () => {
  const declaration = 'Webble is a application where we can share data while retaining ownership and provenance.';
  return <p className="definition">{declaration}</p>;
}

const App: React.FC = () => {
  var headerStyle = {
    fontSize: 100,
    color: "#61dafb"
  };
  var appUrl="";
  var playUrl="";
  return (
    <div className="App">
      <header className="App-header">
        <Container>
          <Row>
            <Col>
              <Declaration />
            </Col>
          </Row>
          <Row>
            <Col>
              <h1 style={headerStyle}>
                Webble
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 style={{padding: 32, color: "#61dafb"}}>
                Decentralized Data Done Right
              </h3>
            </Col>
          </Row>
          <Row>
            <Col xs={{span: 10, offset: 1}}>
              <WebbleTool />
            </Col>
          </Row>
          <Row>
            <Col>
            <a href={appUrl}>
              <img style={{height:65, padding:4}} src="/img/download-on-the-app-store-apple.svg" alt="Download from App Store"/>
            </a>
            <a href={playUrl}>
              <img style={{height:65, padding:4}} src="/img/get-it-on-google-play.svg" alt="Download from Play Store"/>
            </a>
            </Col>
          </Row>
        </Container>
      </header>
      <footer className="App-footer">
        © Copyright 2020 Webble.com
      </footer>
    </div>
  );
}

export default App;
